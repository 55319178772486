import React, { useState } from 'react';
import { ZoomIn, ZoomOut, RotateCw, Info, Menu } from 'lucide-react';

const SecurityKeyViewer = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [scale, setScale] = useState(1);
  const [pin, setPin] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleZoomIn = () => {
    setScale(prev => Math.min(prev + 0.2, 3));
  };

  const handleZoomOut = () => {
    setScale(prev => Math.max(prev - 0.2, 0.5));
  };

  const handleRotate = () => {
    setIsFlipped(prev => !prev);
  };

  const handlePinChange = (e) => {
    setPin(e.target.value);
  };

  const handlePinSubmit = () => {
    if (pin === '1234') {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect PIN. Please try again.');
    }
  };

  const toggleModal = () => {
    setIsModalOpen(prev => !prev);
  };

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(prev => !prev);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - startPosition.x,
        y: e.clientY - startPosition.y,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setIsDragging(true);
    setStartPosition({ x: touch.clientX - position.x, y: touch.clientY - position.y });
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      const touch = e.touches[0];
      setPosition({
        x: touch.clientX - startPosition.x,
        y: touch.clientY - startPosition.y,
      });
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handlePinSubmit();
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="flex items-center justify-center h-screen bg-black">
        <div className="bg-white p-6 rounded shadow-md">
          <h2 className="text-lg font-bold mb-4">Enter PIN</h2>
          <input
            type="password"
            value={pin}
            onChange={handlePinChange}
            onKeyDown={handleKeyDown}
            maxLength={4}
            className="border p-2 mb-4 w-full"
            placeholder="Enter 4-digit PIN"
          />
          <button
            onClick={handlePinSubmit}
            className="bg-black text-white p-2 rounded w-full"
          >
            Submit
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={`relative w-full h-screen overflow-hidden ${isFullScreen ? 'fixed inset-0 z-50 bg-black' : ''}`}>
      {!isFullScreen && (
        <div className="fixed top-0 left-0 w-full bg-black/80 text-white font-bold text-center py-4 z-10">
          VAULT GB213435 - 03.12.2024
        </div>
      )}
      {!isFullScreen && (
        <img
          src={process.env.PUBLIC_URL + '/images/logo.png'}
          alt="Logo"
          className="fixed top-0 left-2 mb-4 w-16 h-16 object-contain z-30"
        />
      )}
      {!isFullScreen && (
        <div className={`absolute top-16 right-0 ${isMenuOpen ? 'h-full bg-black/80' : 'h-12 bg-transparent'} w-full md:w-1/5 shadow-lg z-20 p-4 overflow-y-auto flex flex-col items-center transition-all duration-300`}>
          <button
            onClick={toggleMenu}
            className="fixed top-4 right-4 bg-white/70 backdrop-blur-sm hover:bg-white text-black p-2 rounded-full shadow-lg transition-colors"
          >
            <Menu size={24} />
          </button>
          {isMenuOpen && (
            <ul className="space-y-2 text-white font-semibold">
              <li>My Accounts</li>
              <li>Assets</li>
              <li>Exchange</li>
              <li>Trade</li>
              <li>Pay</li>
              <li>Transfer funds</li>
              <li>Markets</li>
              <li>Portfolio</li>
              <li>Authenticate Physical Coin</li>
              <li>Assign a Value</li>
              <li>Assign Asset</li>
              <li>Assign Commodity</li>
              <li>Create a Bond</li>
              <li>Create a Deed</li>
              <li>Create a Ledger</li>
              <li>Purchase Coins & Notes</li>
              <li>Download Transactions</li>
              <li>View live webcam feed from physical vault</li>
              <li>Useful Links</li>
              <li>Contact</li>
              <li>Help</li>
            </ul>
          )}
        </div>
      )}
      <div
        className="absolute inset-0 overflow-hidden flex items-center justify-center"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onClick={toggleFullScreen}
      >
        <div className="relative w-full h-full">
          <img
            src={process.env.PUBLIC_URL + '/images/front.jpg'}
            alt="Security Key"
            style={{
              transform: `scale(${scale}) ${isFlipped ? 'scaleX(-1)' : ''}`,
              cursor: isDragging ? 'grabbing' : 'grab',
              position: 'absolute',
              top: `calc(50% -50% + ${position.y}px)`,
              left: `calc(50% -50%+ ${position.x}px)`,
              transformOrigin: 'center center',
              width: '100%',
              height: 'auto',
            }}
            className="transition-transform duration-300"
          />
        </div>
      </div>

      {!isFullScreen && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 flex gap-2 z-50">
          <button
            onClick={handleZoomIn}
            className="bg-white/70 backdrop-blur-sm hover:bg-white text-black p-2 md:p-4 rounded-full shadow-lg transition-colors w-auto"
          >
            <ZoomIn size={20} className="md:size-24" />
          </button>
          <button
            onClick={handleZoomOut}
            className="bg-white/70 backdrop-blur-sm hover:bg-white text-black p-2 md:p-4 rounded-full shadow-lg transition-colors w-auto"
          >
            <ZoomOut size={20} className="md:size-24" />
          </button>
          <button
            onClick={handleRotate}
            className="bg-white/70 backdrop-blur-sm hover:bg-white text-black p-2 md:p-4 rounded-full shadow-lg transition-colors w-auto"
          >
            <RotateCw size={20} className="md:size-24" />
          </button>
          <button
            onClick={toggleModal}
            className="bg-white/70 backdrop-blur-sm hover:bg-white text-black p-2 md:p-4 rounded-full shadow-lg transition-colors w-auto"
          >
            <Info size={20} className="md:size-24" />
          </button>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg w-1/3">
            <h2 className="text-lg font-bold mb-4">Transaction Information</h2>
            <p className="mb-4">Here is some information about the transactions...</p>
            <button
              onClick={toggleModal}
              className="bg-black text-white p-2 rounded w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SecurityKeyViewer;